const GoogleIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4 4.02133C12.3529 4.02133 13.6703 4.86489 14.4214 5.56978L17.3565 2.704C15.5538 1.02844 13.208 0 10.4 0C6.33249 0 2.8196 2.33422 1.10938 5.73156L4.47204 8.34311C5.3156 5.83556 7.64982 4.02133 10.4 4.02133Z"
        fill="#EA4335"
      />
      <path
        d="M20.3844 10.6301C20.3844 9.77502 20.3151 9.15102 20.1648 8.50391H10.4004V12.3635H16.1319C16.0164 13.3226 15.3924 14.767 14.0057 15.7377L17.2875 18.2799C19.2519 16.4657 20.3844 13.7964 20.3844 10.6301Z"
        fill="#6487FF"
      />
      <path
        d="M4.48356 12.4558C4.264 11.8087 4.13689 11.1154 4.13689 10.3989C4.13689 9.68247 4.264 8.98914 4.472 8.34202L1.10933 5.73047C0.404444 7.14025 0 8.72336 0 10.3989C0 12.0745 0.404444 13.6576 1.10933 15.0674L4.48356 12.4558Z"
        fill="#FBBC05"
      />
      <path
        d="M10.4002 20.8001C13.2082 20.8001 15.5655 19.8757 17.2873 18.281L14.0055 15.7388C13.1273 16.3513 11.9486 16.7788 10.4002 16.7788C7.64998 16.7788 5.31576 14.9646 4.48376 12.457L1.12109 15.0686C2.83132 18.4659 6.33265 20.8001 10.4002 20.8001Z"
        fill="#34A853"
      />
    </svg>
  );
};

export default GoogleIcon;
