import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Wrapper = styled(LayoutWrapper)`
  min-height: calc(100vh - 60px);

  @media ${themeGet('mediaQueries.mobileOnly')} {
    padding: 60px 0;
  }
`;
