import { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as yup from 'yup';
import { AnimatePresence, motion } from 'framer-motion';
import { heightCollapse } from 'utils/motion/height-collapse';

import AuthLayout from 'layouts/auth-layout/auth-layout';
import { useLocationQuery } from 'utils/hooks/use-query';
import { WALLETS_STATS_AUTH_PAGE } from 'site-settings/site-navigation';

import {
  getOAuthRedirectAPI,
  signInAPI,
  signInConfirmAPI,
  signUpConfirmAPI
} from 'utils/api';
import { useUser } from 'contexts/user.context';
import { useNotify } from 'contexts/notification.context';
import { Input } from 'components/common/form/input';
import { PasswordInput } from 'components/password-input/password-input';
import { Button } from 'components/common/button/button';

import GoogleIcon from 'components/icons/GoogleIcon';

import {
  FormField,
  FormFieldsWrapper,
  FormRow,
  Heading,
  LabelError,
  LabelInput,
  LinkButton,
  Offer,
  SmallButton,
  With,
  Wrapper
} from 'assets/styles/form.style';

import { passwordRe } from 'utils/constant';
import SEO from '../../components/seo';

export default function SignInFormPage() {
  const { t } = useTranslation();
  const { setData } = useUser();
  const [step, setStep] = useState('login');
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const queryToken = useLocationQuery('token');
  const userToken = useLocationQuery('user');
  const { setMessage, setMessageError } = useNotify();

  useEffect(() => {
    (async function () {
      if (userToken !== null) {
        await signUpConfirmAPI(userToken);
        setMessage('notifications.auth.confirm', {
          status: 'success'
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (queryToken !== null) {
      setToken(queryToken);
      setStep('2fa');
    }
  }, []);

  const loginCallback = async (
    values,
    { touched, validateForm, setTouched, setSubmitting, resetForm }
  ) => {
    const errs = await validateForm();

    if (isEmpty(errs)) {
      try {
        setLoading(true);
        const data = await signInAPI(values);
        setLoading(false);

        if (data?.token) {
          setToken(data?.token);
          if (data?.twoFA) {
            setStep('2fa');
          } else {
            localStorage.setItem('access_token', data.token);
            setData(data);
            navigate(WALLETS_STATS_AUTH_PAGE);
          }
        }
      } catch (err) {
        setLoading(false);
        setMessageError(err);
        resetForm();
      }
      setSubmitting(false);
    } else {
      setTouched({ ...touched, ...errs });
    }
  };

  const twoFactorCallback = async (
    values,
    { touched, validateForm, setTouched, resetForm, setSubmitting }
  ) => {
    const errs = await validateForm();

    if (isEmpty(errs)) {
      try {
        setLoading(true);
        const data = await signInConfirmAPI({
          ...values,
          recovery: false,
          token
        });
        setLoading(false);

        if (data?.token) {
          localStorage.setItem('access_token', data.token);
          setData(data);
          navigate(WALLETS_STATS_AUTH_PAGE);
        }
      } catch (err) {
        setLoading(false);
        setMessageError(err);
        resetForm();
      }
      setSubmitting(false);
    } else {
      setTouched({ ...touched, ...errs });
    }
  };

  const twoFactorRecoveryCode = async (
    values,
    { touched, validateForm, setTouched, resetForm, setSubmitting }
  ) => {
    const errs = await validateForm();

    if (isEmpty(errs)) {
      try {
        setLoading(true);
        const data = await signInConfirmAPI({
          ...values,
          recovery: true,
          token
        });
        setLoading(false);

        if (data?.token) {
          localStorage.setItem('access_token', data.token);
          setData(data);
          navigate(WALLETS_STATS_AUTH_PAGE);
        }
      } catch (err) {
        setLoading(false);
        setMessageError(err);
        resetForm();
      }
      setSubmitting(false);
    } else {
      setTouched({ ...touched, ...errs });
    }
  };

  const onGoogleLoginHandler = async () => {
    const data = await getOAuthRedirectAPI();

    if (data?.providerUrl) {
      window.open(data?.providerUrl);
    }
  };

  return (
    <AuthLayout>
      <Wrapper>
        <Heading>{t('authForm.loginText')}</Heading>
        {step === 'login' && (
          <>
            <Formik
              validateOnBlur
              initialValues={{
                login: '',
                password: ''
              }}
              validationSchema={yup.object().shape({
                login: yup
                  .string()
                  .required(t('authForm.loginFields.emailRequired'))
                  .min(4, t('authForm.registerFields.usernameMin'))
                  .matches(
                    /^[a-zA-Z-0-9-@._]+$/,
                    t('authForm.registerFields.usernameMatches')
                  ),
                password: yup
                  .string()
                  .required(t('authForm.loginFields.passwordRequired'))
                  .matches(
                    passwordRe,
                    t('authForm.registerFields.passwordMatches')
                  )
              })}
              onSubmit={loginCallback}
            >
              {(formik) => {
                const {
                  isValid,
                  values,
                  handleChange,
                  handleBlur,
                  touched,
                  errors
                } = formik;
                return (
                  <Form noValidate>
                    <FormFieldsWrapper>
                      <FormRow>
                        <FormField>
                          <LabelInput htmlFor="login">
                            {t('authForm.emailLabel')}
                          </LabelInput>
                          <Input
                            id="login"
                            type="text"
                            name="login"
                            placeholder={t('authForm.emailAddressPlaceholder')}
                            value={values.login}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(errors.login) && touched.login}
                            required
                          />
                          <AnimatePresence>
                            {Boolean(errors.login) && touched.login && (
                              <motion.div
                                initial="from"
                                animate="to"
                                exit="from"
                                variants={heightCollapse()}
                              >
                                <LabelError>{errors.login}</LabelError>
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </FormField>
                      </FormRow>

                      <FormRow>
                        <FormField>
                          <LabelInput htmlFor="password">
                            {t('authForm.passwordLabel')}
                          </LabelInput>
                          <PasswordInput
                            id="password"
                            name="password"
                            placeholder={t('authForm.passwordPlaceholder')}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(errors.password) && touched.password}
                            required
                          />
                          <AnimatePresence>
                            {Boolean(errors.password) && touched.password && (
                              <motion.div
                                initial="from"
                                animate="to"
                                exit="from"
                                variants={heightCollapse()}
                              >
                                <LabelError>{errors.password}</LabelError>
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </FormField>
                      </FormRow>
                    </FormFieldsWrapper>

                    <FormRow>
                      <SmallButton to="/auth/pass-reset">
                        {t('authForm.forgotPassText')}
                      </SmallButton>
                    </FormRow>

                    <Button
                      loading={loading}
                      disabled={!isValid}
                      type="submit"
                      fullwidth
                    >
                      {t('authForm.loginBtnText')}
                    </Button>

                    <Offer align="center">
                      {t('authForm.continueWithText')}
                    </Offer>
                  </Form>
                );
              }}
            </Formik>
            <With>
              <Button
                onClick={onGoogleLoginHandler}
                variant="light"
                icon={<GoogleIcon />}
                fullwidth
              >
                {t('authForm.loginWithGoogleText')}
              </Button>
              {/* <Button variant="light" icon={<FacebookIcon />} fullwidth>
                {t('authForm.loginWithFacebookText')}
              </Button> */}
            </With>
            <Offer align="center">
              {t('authForm.dontHaveAccount')}{' '}
              <LinkButton to="/auth/register">
                {t('authForm.signUpBtnText')}
              </LinkButton>
            </Offer>
          </>
        )}
        {step === '2fa' && (
          <Formik
            validateOnBlur
            initialValues={{
              code: ''
            }}
            validationSchema={yup.object().shape({
              code: yup
                .string()
                .min(6, t('authForm.registerFields.usernameMin'))
                .required(t('authForm.securityCodeRequired'))
            })}
            onSubmit={twoFactorCallback}
          >
            {({
              isValid,
              values,
              handleChange,
              handleBlur,
              touched,
              errors
            }) => (
              <Form noValidate>
                <FormRow>
                  <FormField>
                    <LabelInput htmlFor="code">
                      {t('authForm.securityCodeLabel')}
                    </LabelInput>
                    <Input
                      id="code"
                      name="code"
                      placeholder={t('authForm.securityCodePlaceholder')}
                      value={values.code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.code) && touched.code}
                      required
                    />
                    <AnimatePresence>
                      {Boolean(errors.code) && touched.code && (
                        <motion.div
                          initial="from"
                          animate="to"
                          exit="from"
                          variants={heightCollapse()}
                        >
                          <LabelError>{errors.code}</LabelError>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </FormField>
                </FormRow>

                <FormRow>
                  <SmallButton as="span" onClick={() => setStep('2faRecovery')}>
                    {t('authForm.lost2faCode')}
                  </SmallButton>
                </FormRow>

                <Button
                  loading={loading}
                  disabled={!isValid}
                  type="submit"
                  fullwidth
                >
                  {t('authForm.continueText')}
                </Button>
              </Form>
            )}
          </Formik>
        )}
        {step === '2faRecovery' && (
          <Formik
            validateOnBlur
            initialValues={{
              code: ''
            }}
            validationSchema={yup.object().shape({
              code: yup
                .string()
                .min(6, t('authForm.registerFields.usernameMin'))
                .required(t('authForm.recoveryCodeRequired'))
            })}
            onSubmit={twoFactorRecoveryCode}
          >
            {({
              isValid,
              values,
              handleChange,
              handleBlur,
              touched,
              errors
            }) => (
              <Form noValidate>
                <FormRow>
                  <FormField>
                    <LabelInput htmlFor="code">
                      {t('authForm.recoveryCodeLabel')}
                    </LabelInput>
                    <Input
                      id="code"
                      name="code"
                      placeholder={t('authForm.recoveryCodePlaceholder')}
                      value={values.code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.code) && touched.code}
                      required
                    />
                    <AnimatePresence>
                      {Boolean(errors.code) && touched.code && (
                        <motion.div
                          initial="from"
                          animate="to"
                          exit="from"
                          variants={heightCollapse()}
                        >
                          <LabelError>{errors.code}</LabelError>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </FormField>
                </FormRow>

                <Button
                  loading={loading}
                  disabled={!isValid}
                  type="submit"
                  fullwidth
                >
                  {t('authForm.continueText')}
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Wrapper>
    </AuthLayout>
  );
}

export const Head = () => {
  const { t } = useTranslation();
  return <SEO title={t('authForm.loginText')} />;
};
